
let url_api = process.env.REACT_APP_URL;



function getURLAPI() {

  if(process.env.REACT_APP_URL == undefined) {
    return "https://app.phoner.co/api/module_devis"
  }else{
    return process.env.REACT_APP_URL;
  }


}




let API_URL = getURLAPI();

console.log("API_URL");
console.log(API_URL);

const categoryList = [
  {
    "IDCategorie" : "2",
    "IDPiece" : "4",
    "LibelleCategorie" : "Smartphone",
    "URL_IMG" : "//pro.phonerbusiness.com/ressources/images/devis_smartphone.png"
  },
  {
    "IDCategorie" : "1",
    "IDPiece" : "17",
    "LibelleCategorie" : "Tablette",
    "URL_IMG" : "//pro.phonerbusiness.com/ressources/images/devis_tablette.png"
  },
  {
    "IDCategorie" : "3",
    "IDPiece" : "10",
    "LibelleCategorie" : "Ordinateur",
    "URL_IMG" : "//pro.phonerbusiness.com/ressources/images/devis_macbook.png"
  },
  {
    "IDCategorie" : "4",
    "IDPiece" : "38",
    "LibelleCategorie" : "Objet connecté",
    "URL_IMG" : "//pro.phonerbusiness.com/ressources/images/devis_objet.png"
  }
];

const basicRequestOptions = {
  method: 'GET',
  headers: {'Authorization': 'Basic TG9naW5fRGV2aXNfQXV0aDM1OmZrMmRmZzV0bTAqRXJmaWUz'}
};

const devisRequestOptions = {
  method: 'POST',
  headers: {'Authorization': 'Basic TG9naW5fRGV2aXNfQXV0aDM1OmZrMmRmZzV0bTAqRXJmaWUz',
            'Content-Type': 'application/json'}
};





export default class APIUtilities {
  static generateCategoryList() {
    return categoryList;
  }


  static async generateCreneauList(GUID_Client) {

    let crenau_LIST = [];




    /*

    var phoneList = {"modele_LIST" : []};

    await fetch(API_URL+'/1/'+brandID+'/'+searchedText+'/modele', basicRequestOptions)
      .then((response) => response.json())
      .then((responseJson) => {
        const otherPhone = {"IDModele": 0, "LibelleModele": "Autre", "URL_IMG": "//pro.phonerbusiness.com/ressources/images/modele_telephone.png"};
        phoneList = responseJson;
        phoneList.modele_LIST.push(otherPhone);
    })
    .catch(function(error) {
      console.log(error);
    });
*/
    return crenau_LIST;
  }



  static async generateBrandList() {
    if(typeof(this.brandList) !== 'undefined')
      return this.brandList;

    await fetch(API_URL+'/1/marque', basicRequestOptions).then((response) => response.json())
      .then((responseJson) => {
        const otherBrand = {"IDMarque": 0, "LibelleMarque": "Autre", "Info" : "Autre",
                            "URL_IMG": "//pro.phonerbusiness.com/ressources/images/modele_telephoneV2.png"};
        responseJson.marque_LIST.push(otherBrand);
        this.brandList = responseJson;
      }).catch(function(error) {
        console.log(error);
    });

    return this.brandList;
  }

  static async generateBrandListByPiece(IDPiece) {
    if(typeof(this.brandList) !== 'undefined')
      return this.brandList;

    await fetch(API_URL+'/1/'+ IDPiece +'/marqueV2', basicRequestOptions).then((response) => response.json())
      .then((responseJson) => {
        const otherBrand = {"IDMarque": 0, "LibelleMarque": "Autre", "Info" : "Autre",
                            "URL_IMG": "//pro.phonerbusiness.com/ressources/images/modele_telephoneV2.png"};
        responseJson.marque_LIST.push(otherBrand);
        this.brandList = responseJson;
      }).catch(function(error) {
        console.log(error);
    });

    return this.brandList;
  }

  static async generatePhoneList(brandID, search) {
    var searchedText = search;
    if(searchedText.length === 0)
      searchedText = "-1";

    var phoneList = {"modele_LIST" : []};

    await fetch(API_URL+'/1/'+brandID+'/'+searchedText+'/modele', basicRequestOptions)
      .then((response) => response.json())
      .then((responseJson) => {
        const otherPhone = {"IDModele": 0, "LibelleModele": "Autre", "URL_IMG": "//pro.phonerbusiness.com/ressources/images/modele_telephoneV2.png"};
        phoneList = responseJson;
        phoneList.modele_LIST.push(otherPhone);
    })
    .catch(function(error) {
      console.log(error);
    });

    return phoneList;
  }

  static async generatePhoneListByPiece(brandID, IDPiece, search) {
    var searchedText = search;
    if(searchedText.length === 0)
      searchedText = "-1";

    var phoneList = {"modele_LIST" : []};

    await fetch(API_URL+'/1/'+brandID+'/' + IDPiece + '/' +searchedText+'/modeleV2', basicRequestOptions)
      .then((response) => response.json())
      .then((responseJson) => {
        const otherPhone = {"IDModele": 0, "LibelleModele": "Autre", "URL_IMG": "//pro.phonerbusiness.com/ressources/images/modele_telephoneV2.png"};
        phoneList = responseJson;
        phoneList.modele_LIST.push(otherPhone);
    })
    .catch(function(error) {
      console.log(error);
    });

    return phoneList;
  }

  static async validatePromoCode(code) {
    if(code.length < 4)
      return 0;

    var discountCodeAmount = 0;

    await fetch(API_URL+'/'+code+'/codepromo', basicRequestOptions)
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if(responseJson.montant != null) {
          discountCodeAmount = parseInt(responseJson.montant.valeur);
        }
      })
      .catch(function(error) {
        console.log(error);
    });

    return discountCodeAmount;
  }

  static sendPreDevis(GUID, selectedItems, campaign) {
    this.GUID = GUID;
    const tabTarif = this.getTabTarif(selectedItems);
    var raw = JSON.stringify({
      "IDSite" : 1,
      "GUID_Client" : GUID,
      "GUID_Campagne" : campaign,
      "TabTarif" : tabTarif
    });

    var requestOptions = devisRequestOptions;
    devisRequestOptions.body = raw;

    fetch(API_URL+'/addpreDevis', requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  static async sendDevis(name, email, phone, site, GUID, items, details, promoCode, campaign) {
    const tabTarif = this.getTabTarif(items);

    var raw = JSON.stringify({
      "Prenom" : "",
      "Nom" : name,
      "Email" : email,
      "Tel" : phone,
      "IDSite" : site,
      "GUID_Client" : GUID,
      "GUID_Campagne" : campaign,
      "TabTarif" : tabTarif,
      "MessageAutre" : details,
      "CodePromo" : promoCode
    });

    var requestOptions = devisRequestOptions;
    devisRequestOptions.body = raw;

    await fetch(API_URL+'/addDevis', requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }



  static async sendDemandeRDVDevis(GUID) {

    var raw = JSON.stringify({
      "GUID_Client" : GUID
    });

    var requestOptions = devisRequestOptions;
    devisRequestOptions.body = raw;

    await fetch(API_URL+'/addDemandeRDV_ModuleDevis', requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }



  static async getTimeSlots(date) {
    var day = date.getDate()+'';
    var month = parseInt(date.getMonth()+1)+'';

    day = day.length === 1 ? '0'+ day : day;
    month = month.length === 1 ? '0'+month : month;

    date = date.getFullYear() + '-' + month + '-' + day;
    var slotList = {"periode_LIST" : []};

    await fetch(API_URL+'/1/'+date+'/'+ this.GUID +'/periodeOuverture', basicRequestOptions)
      .then((response) => response.json())
      .then((responseJson) => {
        slotList = responseJson;
      })
      .catch(function(error) {
        console.log(error);
    });

    return slotList;
  }

  static getTabTarif(items) {
    if(items.length === 0)
      return [{"IDTarif" : 1648}];

    const tabTarif = items.map(item => {
      var newItem = {};
      newItem["IDTarif"] = item.IDArticle;
      return newItem;
    });

    return tabTarif;
  }

  static getCampaignId() {
    const params = window.location.search.split('campagne=');
    if(params.length > 1)
      return params[1].split('&')[0];

    return "";
  }
}
