import React, { PureComponent } from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="main-footer">
        <footer>
            <div className="container">
                  <div className="footer-main_footer">
                  <div className="footer-main_wrapper">
                  <div className="footer-link">
                    <div className="col-sm-4 footer-item">
                      <img src="https://static.wixstatic.com/shapes/787bb2_e612ba9b57e043108bacb7c5275f0e5d.svg"/>
                        <p className="para"> Acheter, revendre, réparer et protéger <p className="highlighted">&nbsp;son smartphone</p></p>
                    </div>
                  {/*Container 2*/}
                    <div className="col-sm-2">
                        <h3>Nos services</h3>
                        <ul>
                            <li><a href="https://www.phoner.co/acheter-son-smartphone-neuf-reconditionne">Acheter</a></li>
                            <li><a href="https://www.phoner.co/reprise">Revendre</a></li>
                            <li><a href="/?campagne=footer_module_reco">Réparer</a></li>
                            <li><a href="https://www.phoner.co/protection-1">Protéger</a></li>
                        </ul>
                    </div>
                    {/*Container 3*/}
                    <div className="col-sm-3">
                        <h3>Nous contacter</h3>
                        <ul>
                            <li><a href="http://www.phonerbusiness.com/">Entreprises</a></li>
                            <li><a href="https://www.phoner.co/vitre-35500">Phoner Vitré (35)</a></li>
                            <li><a href="https://www.phoner.co/rennes-centre-35000">Phoner Rennes (35)</a></li>
                            <li><a href="https://www.phoner.co/laval-53000">Phoner Laval (53)</a></li>
                            <li><a href="https://www.phoner.co/phoner-nantes-centre">Phoner Nantes (44)</a></li>
                        </ul>
                    </div>
                    {/*Container 4*/}
                    <div className="col-sm-4">
                        <h3>En savoir plus</h3>
                        <ul>
                            <li><a href="https://www.phoner.co/blog">Blog Phoner</a></li>
                            <li><a href="https://www.phoner.co/mentions-legales">Mentions légales</a></li>
                            <li><a href="https://www.phoner.co/equipe-phoner">L'équipe Phoner</a></li>
                            <li><a href="https://www.phoner.co/conditions-generales-vente">CGV</a></li>
                            <li><a href="https://www.phoner.co/garantie-reparation-vente">Garanties</a></li>
                        </ul>
                    </div>
                    </div>
                    </div>
                    <div className="copyright">
                    <p className="text">© 2024 par Phoner</p>
                    </div>
                </div>
            </div>
      </footer>
    </div>
       );
     };
export default Footer;
