import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams
} from "react-router-dom";



import "./PhoneData.css";

import proctection_LIST from "./_ProtectionList.json";
import prestation_LIST from "./_PrestationList.json";

export default class PhoneData extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedItems : [],
      discount: 0,
      total: 0
    };
  };


  componentDidMount(){

    window.scrollTo({
            top: 0,
            behavior: "smooth",
        })

  }

  static propTypes = {
    onClickFunction: PropTypes.func,
    phoneData: PropTypes.object,
    showPrices: PropTypes.bool
  };





  /*
  printColorOptions(part, colorOptions) {
    if(colorOptions==null)
      return;

    var id="Options"+part;

    return(
      <div class="colorOptions" id={id} style={{display: "none"}}>
      <p>Choisissez votre couleur :</p>
      <p></p>
      {colorOptions.map((color, index) => (
        <label>
					<input type="radio" class="cardRadio" id={id+index} name={part} value={color.value}/>
					<div class="cardColorBorder">
					</div>
					<div class="cardColor" style={{backgroundColor: color.code}}>
					</div>
				</label>
      ))}
      </div>);
  }*/

  printItem(item) {
    return(
      <label className="lab">
      <input type="checkbox" className="item-checkbox" value={item.Piece} id={item.Piece} onClick={() => this.onItemClick(item)}/>
      <div className="phone-data-info-box item-info-box">
        <div className="info-box-label">
          {item.Piece}
          <img src={item.PictoPath_Piece} className="picto-piece"/>
        </div>
        {this.props.showPrices &&
        <div className="info-box-price">
          {item.PrixTTC}€
        </div>}
        <div className="info-box-price">
        <div class="checkmark">
        <input type="checkbox" className="item-checkbox"/>
        </div>
          </div>
      </div>
        {/*<div class="options">
        {this.printColorOptions(item.part, item.colorOptions)}
        </div>*/}
      </label>);
  }

  printItemProtection(item) {
    return(
      <label className="lab">
      <input type="checkbox" className="item-checkbox" value={item.Piece} id={item.Piece} onClick={() => this.onItemClick(item)}/>
      <div className="protection-data-info-box item-info-box-protection">
      <div>
        <img src={item.PictoPath_Piece} className="picto-protection"/>
      </div>
      <div className="box-protection">
        <div className="info-box-label-proctection">
          {item.Piece}
        </div>
        <p className="text-protection">Garantie à vie</p>
      </div>
        {this.props.showPrices &&
        <div className="info-box-price-protection">
          {item.PrixTTC}€
        </div>}
        <div className="info-box-price-protection">
        <div class="checkmark">
        <input type="checkbox" className="item-checkbox"/>
        </div>
          </div>
      </div>
        {/*<div class="options">
        {this.printColorOptions(item.part, item.colorOptions)}
        </div>*/}
      </label>);
  }
  /* Clic sur le choix d'une pièce à réparer */
  onItemClick(item) {

    var array = [...this.state.selectedItems];
    var index = array.indexOf(item);

    if(index === -1)
      array = array.concat(item);

    else
      array.splice(index, 1);

    const discount = this.getDiscount(array);
    const total = this.getTotal(array, discount)
    this.setState({
      selectedItems: array,
      discount: discount,
      total: total
    });
/*
    if(document.getElementById("Options"+src)!=null)
    {
      if(document.getElementById("Options"+src).style.display=="none")
      {
        document.getElementById("Options"+src).style.display = "flex";
        document.getElementById("Options"+src+"0").checked = true;
      }

      else {
        document.getElementById("Options"+src).style.display = "none";
      }
    }*/
  }

  validateItems(selectedItems, discount, total) {
    this.props.onClickFunction(selectedItems, discount, total);
  }

  getDiscount(itemsList) {
    return(itemsList.length < 1 ? 0 : (itemsList.length-1)*10);
  }

  getTotal(itemsList, discount) {
    var price = 0;

    for(var i=0 ; i<itemsList.length; i++)
      price+=parseInt(itemsList[i].PrixTTC);

    return price-discount;
  }

  render() {

    return (
      <div className="component-phone-data">
        <div className="results-title">
          Sélectionnez les réparations sur votre {this.props.phoneData.LibelleMarque} {this.props.phoneData.LibelleModele} ?
        </div>
        <div className="results-subtitle">
          Toutes les réparations Phoner sont garanties 12 mois minimum, et bénéficiez de 10% de remise exclusive en réservant en ligne !
        </div>
        <div className="phone-data-container">
          <div className="component-phone-data-picture">
              <img alt={this.props.phoneData.LibelleModele} src={this.props.phoneData.URL_IMG}/>
          </div>
          <div>
            <div className= "component-phone-data-selection">
              <div className="component-phone-data-item-list">
                {this.props.phoneData.ComposantList.map(item => (
                  <div key={item.IDArticle} className="component-phone-data-item-element">
                    {this.printItem(item)}
                  </div>
                ))}
              </div>

              <div className="class-title">
                Besoin d'autre chose ?
              </div>

              <div className="component-phone-data-item-list">

                {prestation_LIST.map(item => (
                  <div key={item.IDArticle} className="component-phone-data-item-element">
                    {this.printItem(item)}
                  </div>
                ))}

              </div>

              <div className="class-title">
                Protection d'écran
              </div>

              <div className="component-phone-data-item-list">

                {proctection_LIST.map(item => (
                  <div key={item.IDArticle} className="component-phone-data-item-element">
                    {this.printItemProtection(item)}
                  </div>
                ))}

              </div>


              <div className="component-phone-data-prices">
                <div className="phone-data-info-box amount-info-box">
                  <div className="info-box-label">
                    Remise web multi-réparations
                  </div>
                  <div className="info-box-price">
                    {this.state.discount}€
                  </div>
                </div>
                {this.props.showPrices &&
                <div className="phone-data-info-box amount-info-box">
                  <div className="info-box-label">
                    Total
                  </div>
                  <div className="info-box-price">
                    {this.state.total}€
                  </div>
                </div>}
              </div>
              <Router>
                <Link to="/recapitulatif">
                  <button className="validate-button" onClick={this.validateItems.bind(this, this.state.selectedItems, this.state.discount, this.state.total)}>Aller au récapitulatif</button>
                </Link>
               </Router>
            </div>
            <div className="info-texte">
              Nos tarifs sont donnés à titre indicatifs et doivent être confirmés par nos techniciens en point de vente :)
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/*
<Router>
  <Link to="/recapitulatif">
  </Link>
 </Router>
*/
