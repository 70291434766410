import React, { PureComponent } from "react";
import {withStyles} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import PhoneIcon from '@material-ui/icons/Phone';
import BusinessIcon from '@material-ui/icons/Business';

import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
  useHistory
} from "react-router-dom";


const styles = theme => ({
  root: {
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
      width: '100%',
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: 2,
        borderColor: '#D9D9D9',
      },
      '&:hover fieldset': {
        borderColor: '#205685',
      },
    }
  }
});

const sites = [
  {value: 1, label: 'Vitré (35500)'},
  {value: 4, label: 'Laval (53000)'},
  {value: 5, label: 'Rennes (35000)'},
  {value: 12, label: 'Nantes (44000)'}
];

const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
const validPhone = new RegExp('^0+[1-9]{1} ([0-9]{2} ){3}[0-9]{2}')


class DataForm extends PureComponent {
  constructor(props) {
    super(props);
    this.validateForm = this.validateForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      site: "",
      emailHelperText: '',
      emailError: false ,
      nameHelperText: '',
      nameHelper: false,
      phoneNumberHelperText: '',
      phoneNumberHelper: false,
      isErrorChecked : false,
    };

  }


  addTextArea() {
    /* on ajoute une zone de texte si details = 1 cad si la demande est "autre" */
    if(this.props.details)
      return(
        <div>
        <TextField
          id="details"
          label="Votre demande"
          variant="outlined"
          helperText="Saisir votre demande avec le plus de détails possibles"
          multiline rows={4}/>
        </div>
      );
  }

  validateForm(event) {
    event.preventDefault();
    var error = 0;

    if (!validEmail.test(event.target.email.value)) {
      this.setState({
        emailHelperText: 'Ce champ est invalide.',
        emailError: true });
      error = 1;
    }

    else {
      this.setState({
        emailHelperText: '',
        emailError: false });
    }

    if (!validPhone.test(event.target.phone.value)) {
      this.setState({
        phoneNumberHelperText: 'Ce champ est invalide.',
        phoneNumberError: true });
      error = 1;
    }

    else {
      this.setState({
        phoneNumberHelperText: '',
        phoneNumberError: false });
    }

    if(event.target.name.value.length <2 ) {
      this.setState({
        nameHelperText: 'Ce champ est invalide.',
        nameError: true });
      error = 1;
    }
    else {
      this.setState({
        nameHelperText: '',
        nameError: false });
    }

    if(!this.state.site > 0 ) {
      console.log("Ce champ est invalide Site->" + this.state.site)
      this.setState({
        SiteHelperText: 'Sélectionner une boutique.',
        SiteError: true });
      error = 1;
    }  else {
        this.setState({
          SiteHelperText: '',
          SiteError: false });
      }

    if(!event.target.checkbox.checked){
      error = 1;
      this.setState({isErrorChecked : true})
    }else{
      this.setState({isErrorChecked : false})
    }


    if(!error){
        this.props.onSubmit(event, this.state.site);
        this.props.history.push('/recapitulatif/final');
    }

  }

  handlePhoneChange = ({target: {value }}) => {
    value = value
      // Remove all non-digits, turn initial 33 into nothing
      .replace(/\D+/, '')
      .replace(/^330?/, '0')
      // Stick to first 10, ignore later digits
      .slice(0, 13)
      // Add a space after any 2-digit group followed by more digits
      .replace(/(\d{2})(?=\d)/g, '$1 ')
    this.setState({
      phoneNumber: value
    })
  }

  handleChange(event) {
    this.setState({
      site: event.target.value
    });
  }

  render() {
    const {classes} = this.props;

    return (

      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={this.validateForm}>
        <div>
        {this.addTextArea()}
        <hr />
        <div className="title-DataForm">Vos coordonées</div>
        <TextField
          required
          id="name"
          label="Prénom & Nom"
          placeholder="Prénom Nom"
          error={this.state.nameError}
          helperText={this.state.nameHelperText}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}/>
        </div>
        <div>
        <TextField
          required
          id="email"
          placeholder="adresse@monmail.com"
          error={this.state.emailError}
          helperText={this.state.emailHelperText}
          label="Adresse email"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AlternateEmailIcon />
              </InputAdornment>
            ),
          }}/>
        </div>
        <div>
        <TextField
          required
          id="phone"
          label="Numéro de téléphone"
          placeholder="06 12 34 56 78"
          onChange={this.handlePhoneChange}
          value={this.state.phoneNumber}
          error={this.state.phoneNumberError}
          helperText={this.state.phoneNumberHelperText}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneIcon />
              </InputAdornment>
            ),
          }}/>
        </div>
        <div>
        <FormControl variant="outlined">
          <InputLabel id="site-label">Boutique</InputLabel>
          <Select
            style={{
            marginBottom: '16px',}}
            labelId="site-label"
            id="site"
            value={this.state.site}
            onChange={this.handleChange}
            error={this.state.SiteError}
            helperText={this.state.SiteHelperText}
            displayEmpty
            startAdornment={
              <InputAdornment position="start">
                <BusinessIcon />
              </InputAdornment>
            }
            label="Boutique"
          >
            <MenuItem value="" disabled>
              <em>Sélectionner une boutique</em>
            </MenuItem>
            {sites.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
          </div>
          <FormControlLabel
            control={<Checkbox required id="checkbox" name="checkbox" color="primary"/>}
            label="J'accepte que les données saisies soient utilisées dans le cadre de ma demande de devis et de la relation commerciale qui peut en découler"
            />
          <div>
          {
            this.state.isErrorChecked &&
            <p style = {{color: 'red'}}>Cocher la case pour valider</p>
          }
          <button className="validate-button" variant="contained" label="Submit" type="submit">Voir le tarif</button>
          </div>
      </form>

    );
  }
}

export default withStyles(styles)(withRouter(DataForm));
