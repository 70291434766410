import React, { PureComponent } from "react";
import "./Header.css";

const Header = () => {
  return (
    <div className="main-hearder">
      <header className="header-main app__header">
        <div className="header-main_header header_wrapper">
          <a href="https://www.phoner.co">
          <div className="logo">
            <img className="img-logo" src="https://static.wixstatic.com/shapes/787bb2_05865ba5c4e04c1ca3d97cc5e40f38d2.svg"/>
          </div>
          </a>
          <div className="header-main_nav">
          <div className="header-main_nav header-nav">
            <a className="link" href="https://www.phoner.co/acheter-son-smartphone-neuf-reconditionne">
              <span className="header-text">Acheter</span>
            </a>
          </div>
          <div className="header-main_nav header-nav">
            <a className="link" href="https://www.phoner.co/reprise">
              <span className="header-text">Revendre</span>
            </a>
          </div>
          <div className="header-main_nav header-nav">
            <a className="link" href="/">
              <span className="header-text">Réparer</span>
            </a>
          </div>
          <div className="header-main_nav header-nav">
            <a className="link" href="https://www.phoner.co/protection-1">
              <span className="header-text">Protéger</span>
            </a>
          </div>
          <div className="box">
            <a className="link_btn" href="https://www.phoner.co/nous-contacter">
              <span className="button">Nos boutiques</span>
            </a>
        </div>
      </div>
      </div>
      </header>
    </div>
  )
}
export default Header;
