import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import SkeletonCard from "./SkeletonCard";
import APIUtilities from './APIUtilities.js'

export default class BrandResults extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      firstLoad : true
    };
  };

  static propTypes = {
    onClickFunction: PropTypes.func,
    campaign: PropTypes.string
  };

  async getBrandList() {
    const list = await APIUtilities.generateBrandListByPiece(this.props.category.IDPiece);
    this.setState({
      firstLoad : false,
      brandList : list
    });
  }


  componentDidMount() {
    this.getBrandList();
  }

  selectBrand(id, name) {
    this.props.onClickFunction(parseInt(id), name);
  };

  render() {
    if(this.state.firstLoad)
      return <SkeletonCard nbItems="6"/>;
    else {
      return (
        <>
        <div className="results-title">
        Sélectionner la marque de votre {this.props.category.LibelleCategorie}
        </div>
        <div className="component-grid-list">
          {this.state.brandList.marque_LIST.map(brand => (
          <div className="component-grid-element component-results-element" id="component-brands-results-element" key={brand.IDMarque} onClick={this.selectBrand.bind(this, brand.IDMarque, brand.LibelleMarque)}>
            <img alt={brand.LibelleMarque} src={brand.URL_IMG} />
            <span className="component-grid-element-title">{brand.LibelleMarque}</span>
          </div>))}
        </div>
      </>);
    }
  }
}
