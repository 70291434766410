import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import "./SearchInput.css";

export default class SearchInput extends PureComponent {
  static propTypes = {
    textChange: PropTypes.func,
    brandName: PropTypes.string
  };

  handleChange = event => {
    this.props.textChange(event);
  };

  render() {
    return (
      <>
      <div className = "results-title">
      Sélectionner le modèle de votre {this.props.category.LibelleCategorie} {this.props.brandName}
      </div>
      <div className="component-search-input">
          <div className="component-search-box">
          <div className="input-prefix">
          <img src="https://static.wixstatic.com/shapes/787bb2_034c26847f5945bd82b4fbbde155a541.svg" className="img-search"/>
          </div>
            <input id="search" type="text" onChange={this.handleChange} placeholder="Rechercher votre appareil..." />
          </div>
      </div>
      </>
    );
  }
}
