import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import APIUtilities from './APIUtilities.js'
import SkeletonCard from "./SkeletonCard";

export default class RDVResults extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      firstLoad : true,
      numberWeek : 0,
      year : 0
    };
  };

  static propTypes = {
    onClickFunction: PropTypes.func
  };


   getWeekNumber(d) {
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
        // Return array of year and week number
        return [d.getUTCFullYear(), weekNo];
  }


  async getCrenauList() {
    const list = await APIUtilities.generateCreneauList('a8bca01-f284-6736-66b0-a8fad4ae1481');
    this.setState({
      firstLoad : false,
      categoryList : list
    });
  }

  componentDidMount() {

    var result = this.getWeekNumber(new Date());

    this.setState({year : result[0], numberWeek : result[1]})

    
    console.log('getWeekNumber');
    console.log(result);


    this.getCrenauList();

  }

  selectCategory(category) {
    this.props.onClickFunction(category);
  };

  render() {
    if(this.state.firstLoad)
      return <SkeletonCard nbItems="4"/>;
    else {
      return (
        <>
        <div className="results-title">
        Réservez votre créneau dès maintenant
        </div>
        <div className="component-grid-list">
          {this.state.categoryList.map(category => (
            <div className="component-grid-element component-results-element" id="component-categories-results-element" key={category.IDCategorie} onClick={this.selectCategory.bind(this, category)}>
              <img alt ={category.LibelleCategorie} src={category.URL_IMG} />
              <span className="component-grid-element-title">{category.LibelleCategorie}</span>
            </div>
          ))}
        </div>
        </>
      );
    }
  }
}
